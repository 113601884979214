$(document).ready(function($) {

	let submenuItems = $('.module-nav__item');
	let submenuImages = $('.module-nav__image');
	let submenuImagesWithActive = $('.module-nav__image.active');

	$(submenuItems).mouseenter(onMouseenterSubmenuItem);
	$(submenuItems).mouseleave(onMouseleaveSubmenuItem);

	function onMouseenterSubmenuItem() {
		let id = $(this).attr('data-image');
		let img = $('#' + id);
		$(submenuImages).removeClass('active');
		$(img).addClass('active');
	}

	function onMouseleaveSubmenuItem() {
		$(submenuImages).removeClass('active');
		$(submenuImagesWithActive).addClass('active');
	}
});
