import $ from 'jquery';
window.jQuery = $;
window.$ = $;

import 'bootstrap';

import './offcanvas';
import './smoothScroll';
import './modal';
import './submenu';
import './nav-module';
import './navbar';
import './marquee';
import './filter';
import './compare';

import './aos';

// import './scrollspy';

import './watchForHover';

import './browserSync';

