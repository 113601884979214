$(document).ready(function($) {

	let filterTrigger = $('.filter-trigger');
	let filterWrapper = $('.filter-wrapper');

	$(filterTrigger).click(toggleFilter);

	function toggleFilter() {
		if($(filterWrapper).hasClass('active')) {
			$(filterWrapper).removeClass('active');
			$(filterTrigger).removeClass('active');
		} else {
			$(filterWrapper).addClass('active');
			$(filterTrigger).addClass('active');
		}
	}

});
