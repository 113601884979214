$(document).ready(function($) {

	let navbar = $('.navbar');

	$(window).scroll(checkScroll);

	checkScroll();

	function checkScroll () {
		let scroll = $(window).scrollTop();
		if(scroll > 30) {
			$(navbar).addClass('not-transparent');
		} else {
			$(navbar).removeClass('not-transparent');
		}
	}

});
