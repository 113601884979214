$(document).ready(function($) {

	let body = $('body');

	let offCanvasLeft = $('.off-canvas-left');
	let offCanvasRight = $('.off-canvas-right');

	let offCanvasLeftButton = $('.off-canvas-left-button');
	let offCanvasRightButton = $('.off-canvas-right-button');

	let offCanvasCloseButton = $('.off-canvas-close-button');

	let submenuButton = $(offCanvasRight).find('.menu-item-has-children > a');

	$(offCanvasLeftButton).click(openOffCanvasLeftWrapper);
	$(offCanvasRightButton).click(openOffCanvasRightWrapper);
	$(offCanvasCloseButton).click(closeOffCanvas);
	$(submenuButton).click(toggleSubmenu);



	function closeOffCanvas() {
		closeOffCanvasLeft();
		closeOffCanvasRight();
		hideCloseButton();
	}


	function openOffCanvasLeftWrapper() {
		closeOffCanvasRight();
		openOffCanvasLeft();
		showCloseButton();
	}


	function openOffCanvasLeft() {
		$(offCanvasLeft).addClass('active');
		$(offCanvasLeftButton).addClass('active');

		$(body).addClass('modal-open');

		$(offCanvasLeftButton).unbind('click');
		$(offCanvasLeftButton).click(closeOffCanvasLeftWrapper);

	}


	function closeOffCanvasLeftWrapper() {
		closeOffCanvasLeft();
		closeOffCanvasRight();
		hideCloseButton();
	}


	function closeOffCanvasLeft() {

		$(offCanvasLeft).removeClass('active');
		$(offCanvasLeftButton).removeClass('active');

		$(body).removeClass('modal-open');

		$(offCanvasLeftButton).unbind('click');
		$(offCanvasLeftButton).click(openOffCanvasLeftWrapper);

	}


	function openOffCanvasRightWrapper() {
		closeOffCanvasLeft();
		openOffCanvasRight();
		showCloseButton();
	}


	function openOffCanvasRight() {

		$(offCanvasRight).addClass('active');
		$(offCanvasRightButton).addClass('active');

		$(body).addClass('modal-open');

		$(offCanvasRightButton).unbind('click');
		$(offCanvasRightButton).click(closeOffCanvasRightWrapper);

	}


	function closeOffCanvasRightWrapper() {
		closeOffCanvasRight();
		closeOffCanvasLeft();
		hideCloseButton();
	}


	function closeOffCanvasRight() {

		$(offCanvasRight).removeClass('active');
		$(offCanvasRightButton).removeClass('active');

		$(body).removeClass('modal-open');

		$(offCanvasRightButton).unbind('click');
		$(offCanvasRightButton).click(openOffCanvasRightWrapper);

	}


	function showCloseButton() {

		$(offCanvasCloseButton).removeClass('d-none');
		setTimeout(function(){
			$(offCanvasCloseButton).addClass('active');
		}, 50);

	}


	function hideCloseButton() {

		$(offCanvasCloseButton).removeClass('active');
		setTimeout(function(){
			$(offCanvasCloseButton).addClass('d-none');
		}, 500);

	}


	function toggleSubmenu(event) {
		event.preventDefault();
		let parent = $(this).closest('.menu-item-has-children');
		if($(parent).hasClass('active')) {
			$(parent).removeClass('active');
		} else {
			$(parent).addClass('active');
		}
	}

});
