$(document).ready(function($) {

	let modals = $('.modal');

	if(modals.length) {

		$('body').append(modals);

		$(modals).on('hide.bs.modal', function () {

			let videos = $(this).find('video');

			if(videos.length) {

				$(videos).each(function () {
					$(this).trigger('pause');
				});

			}

		});

	}

});
