$(document).ready(function($) {

	let navbar = $('.navbar');
	let menuItems = $('.menu-desktop .menu > .menu-item.has_submenu');
	let allMenuItems = $('.menu-desktop .menu > .menu-item:not(.has_submenu)');
	let submenus = $('.sub-menu__wrapper');

	$(menuItems).mouseenter(onMouseenterMenuItem);
	$(allMenuItems).mouseenter(onMouseleaveMenuItem);
	$(navbar).mouseenter(onMouseleaveMenuItem);
	//$(menuItems).mouseleave(onMouseleaveMenuItem);

	function onMouseenterMenuItem(event) {
		event.stopPropagation();
		let id = $(this).find('> a').attr('data-submenu');
		let submenu = $('#' + id);
		$(submenus).removeClass('active');
		$(submenu).addClass('active');
		$(navbar).addClass('submenuactive');
	}

	function onMouseleaveMenuItem() {
		$(submenus).removeClass('active');
		$(navbar).removeClass('submenuactive');
	}

	let submenuItems = $('.sub-menu__item');
	let submenuImages = $('.sub-menu__image');
	let submenuImagesWithActive = $('.sub-menu__image.active');

	$(submenuItems).mouseenter(onMouseenterSubmenuItem);
	$(submenuItems).mouseleave(onMouseleaveSubmenuItem);

	function onMouseenterSubmenuItem() {
		let id = $(this).attr('data-image');
		let img = $('#' + id);
		$(submenuImages).removeClass('active');
		$(img).addClass('active');
	}

	function onMouseleaveSubmenuItem() {
		$(submenuImages).removeClass('active');
		$(submenuImagesWithActive).addClass('active');
	}
});
