$(document).ready(function($) {

	let pedestalIds = [];

	let body = $('body');
	let compareButtons = $('.module-pedestals__item__compare-button');
	let compareViewButton = $('#module-pedestals__compare-view-button');
	let compareViewButtonReset = $('#module-pedestals__compare-view-button-reset');
	let compareViewCloseButton = $('#compare-view-close-button');
	let compareView = $('#compare-view');
	let compareViewPosts = $('#compare-view-posts');
	let compareViewLoadingSpinner = $('#compare-view-loading-spinner');

	// activate compare buttons
	/*$(compareButtons).each(function () {
		let postId = $(this).attr('data-post-id');
		if(postId && pedestalIds.includes(postId)) {
			$(this).addClass('active');
		}
	});*/

	// setCounter(pedestalIds.length);

	resetFilter();

	$(compareButtons).click(toggleCompare);
	$(compareViewButton).click(toggleCompareView);
	$(compareViewButtonReset).click(resetFilter);
	$(compareViewCloseButton).click(toggleCompareView);

	function toggleCompare(event) {

		event.stopPropagation();

		let postId = $(this).attr('data-post-id');
		if(postId) {
			if(pedestalIds.includes(postId)) {
				// remove compare
				const index = pedestalIds.indexOf(postId);
				if (index > -1) {
					pedestalIds.splice(index, 1);
					$(this).removeClass('active');
					setCounter(pedestalIds.length);
				}
			} else {
				if(pedestalIds.length < 3) {
					// add compare
					pedestalIds.push(postId);
					$(this).addClass('active');
					setCounter(pedestalIds.length);
				}
			}
			localStorage.setItem('pedestalIds', JSON.stringify(pedestalIds));
		}

	}

	function setCounter(counter) {
		if(counter > 0) {

			$(compareViewButton).removeClass('disabled');
			$(compareViewButtonReset).removeClass('disabled');

			let inactiveCompareButtons = $('.module-pedestals__item__compare-button:not(.active)');
			if(counter >= 3) {
				$(inactiveCompareButtons).addClass('disabled');
			} else {
				$(inactiveCompareButtons).removeClass('disabled');
			}

		} else {
			$(compareViewButton).addClass('disabled');
			$(compareViewButtonReset).addClass('disabled');
		}
	}

	function toggleCompareView() {
		if($(compareView).hasClass('active')) {
			$(compareView).removeClass('active');
			$(body).removeClass('modal-open');
			setTimeout(() => {
				$(compareViewPosts).empty();
				$(compareViewLoadingSpinner).removeClass('inactive');
			}, 500);
		} else {
			$(compareView).addClass('active');
			$(body).addClass('modal-open');
			loadCompares();
		}
	}

	function resetFilter () {
		$(compareButtons).removeClass('active');
		$(compareButtons).removeClass('disabled');
		$(compareViewButton).addClass('disabled');
		$(compareViewButtonReset).addClass('disabled');
		localStorage.removeItem('pedestalIds');
		pedestalIds = [];
	}


	// eslint-disable-next-line no-unused-vars
	function loadCompares() {

		$(compareViewPosts).empty();

		$.ajax({
			type: 'POST',
			url: ajaxurl, // eslint-disable-line
			dataType: 'html', // add data type
			data: {
				action : 'get_compare_pedestals',
				pedestalIds: pedestalIds,
				lang: document.getElementsByTagName('html')[0].getAttribute('lang')
			},
			success: function(response) {
				$(compareViewPosts).html(response);
				$(compareViewLoadingSpinner).addClass('inactive');
			}
		});

	}

});
